"use strict";

import { FC, ReactElement, useMemo, useState } from "react";
import LayoutTopBar from "./LayoutTopBar";
import LayoutDrawer from "./drawer/LayoutDrawer";
import LayoutContext from "./LayoutContext";
import { useMeasure } from "@uidotdev/usehooks";
import useUser from "../../user/useUser";
import { SpeedDial, SpeedDialIcon } from "@mui/material";
import UserFeedbackSpeedDialAction from "./speed_dial/UserFeedbackSpeedDialAction";
import DonationSpeedDialAction from "./speed_dial/DonationSpeedDialAction";
import { MiscellaneousServices as MiscellaneousServicesIcon } from "@mui/icons-material";

const styles = {
  speedDial: {
    position: "fixed",
    left: 16,
    bottom: 16,
  },
  speedDialFab: {
    width: 36,
    height: 36,
  },
};

interface Props {
  children: React.ReactNode;
}

const Layout: FC<Props> = ({ children }: Props): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const [ref, { height }] = useMeasure();
  const { user } = useUser();

  const contextValue = useMemo(() => ({ topBarHeight: height }), [height]);

  return (
    <LayoutContext.Provider value={contextValue}>
      <span ref={ref} style={{ display: "block" }}>
        <LayoutTopBar setIsOpen={setIsOpen} />
      </span>
      <LayoutDrawer isOpen={isOpen} setIsOpen={setIsOpen} />
      {children}
      <SpeedDial
        ariaLabel="Miscellaneous"
        icon={<SpeedDialIcon icon={<MiscellaneousServicesIcon />} />}
        FabProps={{ sx: styles.speedDialFab }}
        sx={styles.speedDial}
      >
        {user != null && <UserFeedbackSpeedDialAction />}
        <DonationSpeedDialAction />
      </SpeedDial>
    </LayoutContext.Provider>
  );
};

export default Layout;
