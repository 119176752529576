"use strict";

import { Theme, createTheme } from "@mui/material";

export const darkTheme: Theme = createTheme({
  palette: {
    mode: "dark",
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
});
