"use strict";

import { Button, Menu, MenuItem } from "@mui/material";
import {
  FC,
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Category } from "../common/__generated__/ProblemLikeIconButtonCreateFavoriteMutation.graphql";
import { getBasePathFromCategory } from "../../route/RouteUtils";
import useTheme, { ThemeType } from "../../theme/useTheme";

const styles = {
  light: {
    button: {
      color: "white",
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.1)",
      },
    },
  },
};
const CATEGORY_TITLES = {
  INVESTMENT_BANKING: "Investment Banking",
  TECH: "Product Management",
};

interface Props {
  category: Category;
}

const LayoutTopBarCategoryDialog: FC<Props> = ({ category }): ReactElement => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { themeType } = useTheme();

  const onClose = useCallback(() => setIsOpen(false), []);

  const menuItemOnClick = useCallback(
    (category: Category) => {
      const splits = pathname.split("/");
      splits[1] = getBasePathFromCategory(category);
      navigate(splits.join("/"));
      onClose();
    },
    [navigate, onClose, pathname],
  );

  const categories = useMemo(
    () => Object.keys(CATEGORY_TITLES) as Category[],
    [],
  );

  return (
    <>
      <Button
        ref={ref}
        size="large"
        onClick={() => setIsOpen(true)}
        sx={themeType === ThemeType.DARK ? {} : styles.light.button}
      >
        {CATEGORY_TITLES[category]}
      </Button>
      <Menu anchorEl={ref.current} open={isOpen} onClose={onClose}>
        {categories.map((c) => (
          <MenuItem
            key={c}
            selected={c === category}
            onClick={() => menuItemOnClick(c)}
          >
            {CATEGORY_TITLES[c]}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LayoutTopBarCategoryDialog;
