"use strict";

import { FC, ReactElement, ReactNode } from "react";
import { CircularProgress, CircularProgressProps, Stack } from "@mui/material";

interface Props {
  label?: ReactNode;
  size?: CircularProgressProps["size"];
}

const PageCircularProgress: FC<Props> = ({
  label,
  size,
}: Props): ReactElement => {
  return (
    <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
      <CircularProgress size={size} />
      {label}
    </Stack>
  );
};

export default PageCircularProgress;
