"use strict";

import { Theme, createTheme } from "@mui/material";

export const lightTheme: Theme = createTheme({
  palette: {
    mode: "light",
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: "#F4F4F4",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#F4F4F4",
        },
      },
    },
  },
});
