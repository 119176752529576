"use strict";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import React, { FC, useEffect, useMemo, useState } from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Suspensable from "./components/common/Suspensable";
import useEnvironment from "./useEnvironment";
import { RelayEnvironmentProvider, useMutation } from "react-relay";
import Layout from "./components/layout/Layout";
import useLog from "./log/useLog";
import { useAuth0 } from "@auth0/auth0-react";
import { graphql } from "babel-plugin-relay/macro";
import { AppMutation } from "./__generated__/AppMutation.graphql";
import useTheme from "./theme/useTheme";
import UserContext from "./user/UserContext";
import PageCircularProgress from "./components/common/PageCircularProgress";
import useUser, { User } from "./user/useUser";
import { getCategoryFromPathname } from "./route/RouteUtils";

const LandingView = React.lazy(
  () => import("./components/landing_view/LandingView"),
);
const PrivacyView = React.lazy(
  () => import("./components/privacy_and_terms_view/PrivacyView"),
);
const TermsView = React.lazy(
  () => import("./components/privacy_and_terms_view/TermsView"),
);
const DesktopView = React.lazy(
  () => import("./components/desktop_view/DesktopView"),
);
const ProfileView = React.lazy(
  () => import("./components/profile_view/ProfileView"),
);
const ProblemSearchView = React.lazy(
  () => import("./components/search_view/problem/ProblemSearchView"),
);
const ProblemUnitView = React.lazy(
  () => import("./components/unit_view/common/ProblemUnitView"),
);
const ProblemEditView = React.lazy(
  () => import("./components/mutation_view/problem/edit_view/ProblemEditView"),
);
const ProblemCreationView = React.lazy(
  () =>
    import(
      "./components/mutation_view/problem/creation_view/ProblemCreationView"
    ),
);
const TagSearchView = React.lazy(
  () => import("./components/search_view/tag/TagSearchView"),
);
const TagCreationView = React.lazy(
  () => import("./components/mutation_view/tag/creation_view/TagCreationView"),
);
const TagUpdateView = React.lazy(
  () => import("./components/mutation_view/tag/update_view/TagUpdateView"),
);
const MockInterviewView = React.lazy(
  () => import("./components/mock_interview_view/MockInterviewView"),
);

const CategoryValidator: FC = () => {
  const { pathname } = useLocation();
  if (getCategoryFromPathname(pathname) == null) {
    return <Navigate to="/" replace={true} />;
  }
  return <Outlet />;
};

const AppContent: FC = () => {
  const { fetchUser } = useUser();
  const log = useLog();
  const [commit, isInFlight] = useMutation<AppMutation>(graphql`
    mutation AppMutation {
      get_or_create_user {
        id
      }
    }
  `);

  useEffect(() => {
    log({ viewType: null, eventType: "PAGE_LOAD" });
  }, [log]);

  useEffect(() => {
    commit({
      variables: {},
      onCompleted: ({ get_or_create_user }) =>
        fetchUser(get_or_create_user?.id ?? null),
    });
  }, [commit, fetchUser]);

  if (isInFlight) {
    return <PageCircularProgress label="Fetching User Info..." size={20} />;
  }
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<LandingView />} />
        <Route path="/privacy">
          <Route index element={<PrivacyView />} />
        </Route>
        <Route path="/terms">
          <Route index element={<TermsView />} />
        </Route>
        <Route path="/profile">
          <Route index element={<ProfileView />} />
        </Route>

        <Route path="/tags/create" element={<TagCreationView />} />
        <Route path="/problems/create" element={<ProblemCreationView />} />

        <Route path="/:category" element={<CategoryValidator />}>
          <Route index element={<DesktopView />} />
          <Route path="tags">
            <Route index element={<TagSearchView />} />
            <Route path=":tagName/update" element={<TagUpdateView />} />
          </Route>
          <Route path="problems">
            <Route index element={<ProblemSearchView />} />
            <Route path=":serialNumber">
              <Route index element={<ProblemUnitView />} />
              <Route path="edit" element={<ProblemEditView />} />
            </Route>
          </Route>
          <Route path="mock_interview" element={<MockInterviewView />} />
        </Route>

        <Route path="*" element={<Navigate to="/" replace={true} />} />
      </Routes>
    </Layout>
  );
};

const AppContentWithUserContext: FC = () => {
  const [user, setUser] = useState<User | null>(null);

  const userContextValue = useMemo(() => ({ user, setUser }), [user]);

  return (
    <UserContext.Provider value={userContextValue}>
      <AppContent />
    </UserContext.Provider>
  );
};

export const App: FC = () => {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const { theme } = useTheme();
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const environment = useEnvironment(accessToken);

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        (async () => {
          const accessToken = await getAccessTokenSilently();
          setAccessToken(accessToken);
        })();
      } else {
        setAccessToken(null);
      }
    }
  }, [isLoading, isAuthenticated, getAccessTokenSilently, setAccessToken]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {isLoading ? (
        <PageCircularProgress label="Authenticating..." size={20} />
      ) : (
        <RelayEnvironmentProvider environment={environment}>
          <BrowserRouter>
            <Suspensable
              fallback={<PageCircularProgress label="Loading..." size={20} />}
            >
              <AppContentWithUserContext />
            </Suspensable>
          </BrowserRouter>
        </RelayEnvironmentProvider>
      )}
    </ThemeProvider>
  );
};
