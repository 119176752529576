"use strict";

import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { FC, ReactElement, useMemo, useState } from "react";
import {
  AccountBalance as AccountBalanceIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  Quiz as QuizIcon,
  School as SchoolIcon,
  Tag as TagIcon,
} from "@mui/icons-material";
import useUser from "../../../user/useUser";

const styles = {
  expandIcon: {
    marginLeft: 1,
  },
  indentListItemButton: {
    paddingLeft: 4,
  },
};

interface Props {
  onListItemClick: (pathname: string) => void;
}

const LayoutDrawerInvestmentBankingItemButton: FC<Props> = ({
  onListItemClick,
}: Props): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useUser();

  const isAdmin = useMemo(() => user?.role === "EDITOR", [user?.role]);

  return (
    <>
      <ListItemButton onClick={() => setIsOpen(!isOpen)}>
        <ListItemIcon>
          <AccountBalanceIcon />
        </ListItemIcon>
        <ListItemText primary="Investment Banking" />
        {isOpen ? (
          <ExpandLessIcon sx={styles.expandIcon} />
        ) : (
          <ExpandMoreIcon sx={styles.expandIcon} />
        )}
      </ListItemButton>
      <Collapse in={isOpen} timeout={0} unmountOnExit={true}>
        <List>
          <ListItemButton
            onClick={() => onListItemClick("/investment_banking/problems")}
            sx={styles.indentListItemButton}
          >
            <ListItemIcon>
              <QuizIcon />
            </ListItemIcon>
            <ListItemText primary="Questions" />
          </ListItemButton>
          <ListItemButton
            onClick={() =>
              onListItemClick("/investment_banking/mock_interview")
            }
            sx={styles.indentListItemButton}
          >
            <ListItemIcon>
              <SchoolIcon />
            </ListItemIcon>
            <ListItemText primary="Mock Interview" />
          </ListItemButton>
          {isAdmin && (
            <ListItemButton
              onClick={() => onListItemClick("/investment_banking/tags")}
              sx={styles.indentListItemButton}
            >
              <ListItemIcon>
                <TagIcon />
              </ListItemIcon>
              <ListItemText primary="Tags" />
            </ListItemButton>
          )}
        </List>
      </Collapse>
    </>
  );
};

export default LayoutDrawerInvestmentBankingItemButton;
