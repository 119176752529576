"use strict";

import { useAuth0 } from "@auth0/auth0-react";
import {
  AccountCircle as AccountCircleIcon,
  Logout as LogoutIcon,
} from "@mui/icons-material";
import { Avatar, IconButton, Menu, MenuItem } from "@mui/material";
import {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import useLog from "../../log/useLog";
import { useNavigate } from "react-router-dom";
import useUser from "../../user/useUser";

const styles = {
  avatar: {
    width: 32,
    height: 32,
  },
  iconButton: {
    padding: 0,
  },
};

const UserProfileIcon: FC = (): ReactElement => {
  const { user } = useUser();

  if (user == null) {
    return <AccountCircleIcon />;
  }
  const { picture_url, display_id } = user;
  return (
    <Avatar
      src={picture_url ?? undefined}
      alt={display_id ?? undefined}
      sx={styles.avatar}
    />
  );
};

const ProfileIconButton: FC = (): ReactElement => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [shouldNavigateToProfileView, setShouldNavigateToProfileView] =
    useState(false);
  const navigate = useNavigate();
  const { logout } = useAuth0();
  const log = useLog();

  useEffect(() => {
    if (shouldNavigateToProfileView) {
      setShouldNavigateToProfileView(false);
      navigate("/profile");
    }
  }, [navigate, shouldNavigateToProfileView]);

  const onUserProfileIconButtonClick = useCallback(() => {
    setIsOpen(true);
    log({ viewType: null, eventType: "ON_PROFILE_ICON_BUTTON_CLICK" });
  }, [log]);

  const onClose = useCallback(() => setIsOpen(false), []);

  const onProfileMenuItemClick = useCallback(() => {
    setShouldNavigateToProfileView(true);
    onClose();
  }, [onClose]);

  return (
    <>
      <IconButton
        ref={ref}
        onClick={onUserProfileIconButtonClick}
        sx={styles.iconButton}
      >
        <UserProfileIcon />
      </IconButton>
      <Menu anchorEl={ref.current} open={isOpen} onClose={onClose}>
        <MenuItem onClick={onProfileMenuItemClick}>
          <AccountCircleIcon sx={{ marginRight: 1 }} /> Profile
        </MenuItem>
        <MenuItem
          onClick={() =>
            logout({
              logoutParams: { returnTo: window.location.origin },
            })
          }
        >
          <LogoutIcon sx={{ marginRight: 1 }} />
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default ProfileIconButton;
