/**
 * @generated SignedSource<<ca00c32542e594bc09c4da0aa67782c5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Role = "EDITOR" | "USER";
export type useUserQuery$variables = {
  id?: string | null | undefined;
};
export type useUserQuery$data = {
  readonly fetch__user: {
    readonly display_id: string | null | undefined;
    readonly id: string;
    readonly name: string | null | undefined;
    readonly picture_url: string | null | undefined;
    readonly resume_url: string | null | undefined;
    readonly role: Role;
  } | null | undefined;
};
export type useUserQuery = {
  response: useUserQuery$data;
  variables: useUserQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "fetch__user",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "display_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "picture_url",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "resume_url",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "role",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUserQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUserQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a3c7cab809a703904a48690b9d9bc281",
    "id": null,
    "metadata": {},
    "name": "useUserQuery",
    "operationKind": "query",
    "text": "query useUserQuery(\n  $id: ID\n) {\n  fetch__user(id: $id) {\n    id\n    name\n    display_id\n    picture_url\n    resume_url\n    role\n  }\n}\n"
  }
};
})();

(node as any).hash = "ed5265aa18dc056071feab2fbaf1d315";

export default node;
