"use strict";

import { Category } from "../components/mutation_view/problem/__generated__/ProblemMutationViewProblemRelationsAutoCompleteQuery.graphql";

export function getCategoryFromPathname(pathname: string): Category | null {
  try {
    return getCategoryFromBasePath(pathname.substring(1).split("/", 1)[0]);
  } catch {
    return null;
  }
}

export function getCategoryFromBasePath(basePath: string): Category {
  switch (basePath) {
    case "investment_banking":
      return "INVESTMENT_BANKING";
    case "product_manager":
      return "TECH";
    default:
      throw new Error(`Unsupport basePath ${basePath}`);
  }
}

export function getBasePathFromCategory(category: Category): string {
  switch (category) {
    case "INVESTMENT_BANKING":
      return "investment_banking";
    case "TECH":
      return "product_manager";
  }
}
