"use strict";

import {
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Toolbar,
} from "@mui/material";
import { AddCircleOutlineRounded as AddCircleOutlineRoundedIcon } from "@mui/icons-material";
import { FC, ReactElement, useCallback, useMemo } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate } from "react-router-dom";
import LayoutDrawerInvestmentBankingItemButton from "./LayoutDrawerInvestmentBankingItemButton";
import useUser from "../../../user/useUser";
import LayoutDrawerProductManagerItemButton from "./LayoutDrawerProductManagerItemButton";

const styles = {
  indentListItemButton: {
    paddingLeft: 4,
  },
};
interface Props {
  isOpen: boolean;
  setIsOpen: (newValue: boolean) => void;
}

const LayoutDrawer: FC<Props> = ({
  isOpen,
  setIsOpen,
}: Props): ReactElement => {
  const navigate = useNavigate();
  const { user } = useUser();

  const isAdmin = useMemo(() => user?.role === "EDITOR", [user?.role]);

  const onListItemClick = useCallback(
    (pathname: string) => {
      setIsOpen(false);
      navigate({ pathname });
    },
    [navigate, setIsOpen],
  );

  return (
    <SwipeableDrawer
      anchor="left"
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
    >
      <div style={{ minWidth: "240px" }}>
        <Toolbar sx={{ justifyContent: "flex-end", paddingX: [1] }}>
          <IconButton onClick={() => setIsOpen(false)}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          <LayoutDrawerInvestmentBankingItemButton
            onListItemClick={onListItemClick}
          />
          <LayoutDrawerProductManagerItemButton
            onListItemClick={onListItemClick}
          />
          {isAdmin && (
            <>
              <Divider />
              <ListItemButton
                onClick={() => onListItemClick("/problems/create")}
                sx={styles.indentListItemButton}
              >
                <ListItemIcon>
                  <AddCircleOutlineRoundedIcon />
                </ListItemIcon>
                <ListItemText primary="Create Question" />
              </ListItemButton>
              <ListItemButton
                onClick={() => onListItemClick("/tags/create")}
                sx={styles.indentListItemButton}
              >
                <ListItemIcon>
                  <AddCircleOutlineRoundedIcon />
                </ListItemIcon>
                <ListItemText primary="Create Tag" />
              </ListItemButton>
            </>
          )}
        </List>
      </div>
    </SwipeableDrawer>
  );
};

export default LayoutDrawer;
