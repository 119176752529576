"use strict";

import { useCallback } from "react";
import { useMutation } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import {
  LogEventType,
  LogViewType,
  useLogMutation,
} from "./__generated__/useLogMutation.graphql";

export type LogSchema = {
  viewType: LogViewType | null;
  eventType: LogEventType;
  context?: { [key: string]: string | number | boolean | null };
};

export default function useLog(): (logSchema: LogSchema) => void {
  const [commit] = useMutation<useLogMutation>(graphql`
    mutation useLogMutation(
      $dateTime: String!
      $viewType: LogViewType
      $eventType: LogEventType!
      $url: String!
      $jsonEncodedContext: String
    ) {
      log(
        date_time: $dateTime
        view_type: $viewType
        event_type: $eventType
        url: $url
        json_encoded_context: $jsonEncodedContext
      )
    }
  `);

  const log = useCallback(
    ({ viewType, eventType, context }: LogSchema) => {
      commit({
        variables: {
          dateTime: new Date().toISOString(),
          viewType,
          eventType,
          url: location.href,
          jsonEncodedContext: context == null ? null : JSON.stringify(context),
        },
      });
    },
    [commit],
  );

  return log;
}
