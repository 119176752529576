"use strict";

import { Theme } from "@mui/material";
import { useLocalStorage } from "@uidotdev/usehooks";
import { darkTheme } from "./darkTheme";
import { lightTheme } from "./lightTheme";
import { useCallback, useMemo } from "react";

export enum ThemeType {
  LIGHT = "light",
  DARK = "dark",
}

const THEME = "THEME";

export default function useTheme(): {
  themeType: ThemeType;
  theme: Theme;
  toggleTheme: () => ThemeType;
} {
  const [themeType, setThemeType] = useLocalStorage<ThemeType>(
    THEME,
    ThemeType.LIGHT,
  );

  const isDarkTheme = useMemo(() => themeType === ThemeType.DARK, [themeType]);

  const toggleTheme = useCallback(() => {
    const newThemeType = isDarkTheme ? ThemeType.LIGHT : ThemeType.DARK;
    setThemeType(newThemeType);
    return newThemeType;
  }, [isDarkTheme, setThemeType]);

  return {
    themeType,
    theme: isDarkTheme ? darkTheme : lightTheme,
    toggleTheme,
  };
}
