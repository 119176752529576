"use strict";

import { FC, ReactElement, useCallback, useMemo, useState } from "react";
import { Button, debounce, SxProps, Theme } from "@mui/material";
import { ContentCopy as ContentCopyIcon } from "@mui/icons-material";
import TooltipWithStopPropogation from "./TooltipWithStopPropogation";

const DEBOUNCE_IN_MILLISECONDS = 1000;

interface Props {
  content: string;
  sx?: SxProps<Theme>;
}

const CopyToClipboardButton: FC<Props> = ({ content, sx }): ReactElement => {
  const [isCopied, setIsCopied] = useState(false);

  const resetIsCopied = useMemo(
    () => debounce(() => setIsCopied(false), DEBOUNCE_IN_MILLISECONDS),
    [],
  );

  const onClick = useCallback(() => {
    navigator.clipboard.writeText(content);
    setIsCopied(true);
    resetIsCopied();
  }, [content, resetIsCopied]);

  return (
    <TooltipWithStopPropogation
      title={isCopied ? "Copied!" : "Copy to Clipboard"}
    >
      <Button
        variant="contained"
        size="small"
        endIcon={<ContentCopyIcon />}
        onClick={onClick}
        sx={sx}
      >
        Copy
      </Button>
    </TooltipWithStopPropogation>
  );
};

export default CopyToClipboardButton;
