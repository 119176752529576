"use strict";

import React from "react";
import { User } from "./useUser";

type UserContextType = {
  user: User | null;
  setUser: (user: User | null) => void;
};

export default React.createContext<UserContextType>({
  user: null,
  setUser: () => {},
});
