"use strict";

import { CircularProgress } from "@mui/material";
import { FC, ReactElement, Suspense } from "react";
import ErrorBoundary from "./ErrorBoundary";

interface Props {
  children: React.ReactNode;
  fallback?: React.ReactNode;
}

const Suspensable: FC<Props> = ({
  children,
  fallback,
}: Props): ReactElement => {
  return (
    <ErrorBoundary>
      <Suspense fallback={fallback ?? <CircularProgress />}>
        {children}
      </Suspense>
    </ErrorBoundary>
  );
};

export default Suspensable;
