"use strict";

import React from "react";

type LayoutContextType = {
  topBarHeight: number | null;
};

export default React.createContext<LayoutContextType>({
  topBarHeight: null,
});
