"use strict";

import { useAuth0 } from "@auth0/auth0-react";
import { Menu as MenuIcon } from "@mui/icons-material";
import {
  AppBar,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Toolbar,
  styled,
} from "@mui/material";
import { FC, ReactElement, useCallback } from "react";
import ProfileIconButton from "./ProfileIconButton";
import useLog from "../../log/useLog";
import LayoutTopBarCategoryDialog from "./LayoutTopBarCategoryDialog";
import ThemeSwitch from "../common/theme_switch/ThemeSwitch";
import useCategory from "./useCategory";

interface Props {
  setIsOpen: (newValue: boolean) => void;
}

const LayoutTopBar: FC<Props> = ({ setIsOpen }: Props): ReactElement => {
  const category = useCategory();
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const log = useLog();
  const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);

  const onMenuIconButtonClick = useCallback(() => {
    setIsOpen(true);
    log({ viewType: null, eventType: "ON_MENU_ICON_BUTTON_CLICK" });
  }, [setIsOpen, log]);

  const onLoginButtonClick = useCallback(() => {
    loginWithRedirect();
    log({ viewType: null, eventType: "ON_LOGIN_ICON_BUTTON_CLICK" });
  }, [loginWithRedirect, log]);

  return (
    <>
      <AppBar>
        <Toolbar>
          <IconButton onClick={onMenuIconButtonClick}>
            <MenuIcon />
          </IconButton>
          <Stack direction="row" flexGrow={1} gap={1} alignItems="center">
            {category != null && (
              <LayoutTopBarCategoryDialog category={category} />
            )}
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            gap={isAuthenticated ? 1 : 0}
          >
            <ThemeSwitch />
            {isLoading ? (
              <CircularProgress />
            ) : isAuthenticated ? (
              <ProfileIconButton />
            ) : (
              <Button color="inherit" onClick={onLoginButtonClick}>
                Login
              </Button>
            )}
          </Stack>
        </Toolbar>
      </AppBar>
      <Offset />
    </>
  );
};

export default LayoutTopBar;
