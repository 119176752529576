import {
  Environment,
  FetchFunction,
  Network,
  Observable,
  RecordSource,
  Store,
  SubscribeFunction,
} from "relay-runtime";
import config from "./config";
import { useCallback, useMemo } from "react";
import { createClient } from "graphql-sse";

type Headers = {
  "Content-Type"?: string;
  "Access-Control-Allow-Origin": string;
  Authorization?: string;
};

function getHeaders(
  contentType: string | null,
  accessToken: string | null,
): Headers {
  const headers: Headers = {
    "Access-Control-Allow-Origin": window.location.origin,
  };
  if (contentType != null) {
    headers["Content-Type"] = contentType;
  }
  if (accessToken != null) {
    headers.Authorization = `Bearer ${accessToken}`;
  }
  return headers;
}

export default function useEnvironment(
  accessToken: string | null,
): Environment {
  const fetchQuery: FetchFunction = useCallback(
    async (request, variables, _cacheConfig, uploadables) => {
      const { text } = request;
      let response;
      if (uploadables == null) {
        response = await fetch(config.api.baseUrl, {
          method: "POST",
          headers: getHeaders("application/json", accessToken),
          body: JSON.stringify({ query: text, variables }),
          credentials: "include",
        });
      } else {
        const formData = new FormData();
        formData.append(
          "operations",
          JSON.stringify({ query: text, variables }),
        );
        if (uploadables != null) {
          formData.append("map", JSON.stringify({ file: ["variables.file"] }));
          Object.entries(uploadables).forEach(([key, value]) =>
            formData.append(key, value),
          );
        }
        response = await fetch(config.api.baseUrl, {
          method: "POST",
          headers: getHeaders(null, accessToken),
          body: formData,
          credentials: "include",
        });
      }
      return await response.json();
    },
    [accessToken],
  );

  const subscriptionsClient = useMemo(
    () =>
      createClient({
        url: config.api.baseUrl,
        headers: getHeaders(null, accessToken),
        credentials: "include",
      }),
    [accessToken],
  );

  const setupSubscription: SubscribeFunction = useCallback(
    (operation, variables) => {
      return Observable.create((sink) => {
        if (!operation.text) {
          return sink.error(new Error("Operation text cannot be empty"));
        }
        return subscriptionsClient.subscribe(
          {
            operationName: operation.name,
            query: operation.text,
            variables,
          },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          sink,
        );
      });
    },
    [subscriptionsClient],
  );

  return new Environment({
    network: Network.create(fetchQuery, setupSubscription),
    store: new Store(new RecordSource()),
  });
}
