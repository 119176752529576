import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";
import config from "./config";
import { createRoot } from "react-dom/client";

createRoot(document.getElementById("root") as NonNullable<HTMLElement>).render(
  <Auth0Provider
    domain={config.auth0.domain}
    clientId={config.auth0.clientID}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: config.auth0.audience,
      scope: "read:current_user",
    }}
  >
    <App />
  </Auth0Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
