"use strict";

import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
} from "@mui/material";
import React from "react";

const styles = {
  stackTrace: {
    overflow: "auto",
  },
};

interface Props {
  children: React.ReactNode;
}

interface State {
  error: Error | null | undefined;
}

export default class ErrorBoundary extends React.Component<Props, State> {
  state: State = { error: null };

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  render(): React.ReactNode {
    const { error } = this.state;
    if (error == null) {
      return this.props.children;
    }
    const { name, message, stack } = error;
    return (
      <Alert severity="error">
        <AlertTitle>Error: {name}</AlertTitle>
        {message}
        {stack != null && (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              Stack Trace
            </AccordionSummary>
            <AccordionDetails sx={styles.stackTrace}>
              {stack.split("\n").map((line, index) => (
                <pre key={index}>{line}</pre>
              ))}
            </AccordionDetails>
          </Accordion>
        )}
      </Alert>
    );
  }
}
