"use strict";

import { FC, useCallback, useState } from "react";
import { SxProps, Theme, Tooltip } from "@mui/material";

interface Props {
  title: string;
  children: React.ReactElement;
  sx?: SxProps<Theme>;
}

const TooltipWithStopPropogation: FC<Props> = ({ title, children, sx }) => {
  const [open, setOpen] = useState(false);

  const seOpenWithStopPropogation = useCallback(
    (event: Event | React.SyntheticEvent<Element, Event>, newOpen: boolean) => {
      event.stopPropagation();
      setOpen(newOpen);
    },
    [setOpen],
  );

  return (
    <Tooltip
      open={open}
      onOpen={(event) => seOpenWithStopPropogation(event, true)}
      onClose={(event) => seOpenWithStopPropogation(event, false)}
      onClick={(event) => seOpenWithStopPropogation(event, true)}
      onMouseOut={(event) => seOpenWithStopPropogation(event, false)}
      title={title}
      sx={sx}
    >
      {children}
    </Tooltip>
  );
};

export default TooltipWithStopPropogation;
