"use strict";

import { FC, ReactElement, ReactNode, useMemo } from "react";
import { Modal, ModalProps, Stack, SxProps, Theme } from "@mui/material";
import useTheme, { ThemeType } from "../../../theme/useTheme";

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: 800,
    minHeight: 400,
    maxHeight: "90%",
    border: "2px solid black",
  },
  dark: {
    modal: {
      backgroundColor: "rgb(18, 18, 18)",
    },
  },
  light: {
    modal: {
      backgroundColor: "white",
    },
  },
};

interface Props extends Omit<ModalProps, "children"> {
  children: ReactNode;
  InnerProps?: { sx?: SxProps<Theme> };
}

const ScrollableModal: FC<Props> = ({
  children,
  InnerProps,
  ...props
}): ReactElement => {
  const { themeType } = useTheme();

  const innerPropsSx = useMemo(() => {
    const { sx = [] } = InnerProps ?? {};
    return [
      {
        ...styles.modal,
        ...(themeType === ThemeType.DARK
          ? styles.dark.modal
          : styles.light.modal),
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ];
  }, [InnerProps, themeType]);

  return (
    <Modal {...props}>
      <Stack sx={innerPropsSx} padding={3} gap={2} overflow="scroll">
        {children}
      </Stack>
    </Modal>
  );
};

export default ScrollableModal;
