"use strict";

import { useLocation } from "react-router-dom";
import { getCategoryFromPathname } from "../../route/RouteUtils";
import { useMemo } from "react";
import { Category } from "../common/__generated__/ProblemLikeIconButtonCreateFavoriteMutation.graphql";

export default function useCategory(): Category | null {
  const { pathname } = useLocation();

  return useMemo(() => getCategoryFromPathname(pathname), [pathname]);
}
