"use strict";

import { FC, ReactElement, useCallback, useMemo, useState } from "react";
import { IconContainerProps, Rating, RatingProps, Stack } from "@mui/material";
import {
  SentimentDissatisfied as SentimentDissatisfiedIcon,
  SentimentSatisfied as SentimentSatisfiedIcon,
  SentimentSatisfiedAlt as SentimentSatisfiedAltIcon,
  SentimentVeryDissatisfied as SentimentVeryDissatisfiedIcon,
  SentimentVerySatisfied as SentimentVerySatisfiedIcon,
} from "@mui/icons-material";

const IconContainer: FC<IconContainerProps> = (props): ReactElement => {
  const { value, ...other } = props;

  const icon = useMemo(() => {
    switch (value) {
      case 1:
        return <SentimentVeryDissatisfiedIcon color="error" fontSize="large" />;
      case 2:
        return <SentimentDissatisfiedIcon color="error" fontSize="large" />;
      case 3:
        return <SentimentSatisfiedIcon color="warning" fontSize="large" />;
      case 4:
        return <SentimentSatisfiedAltIcon color="success" fontSize="large" />;
      case 5:
        return <SentimentVerySatisfiedIcon color="success" fontSize="large" />;
      default:
        throw new Error(`Invalid rating ${value}`);
    }
  }, [value]);

  return <span {...other}>{icon}</span>;
};

export interface Props {
  value: number | null;
  onChange: NonNullable<RatingProps["onChange"]>;
  showLabel?: boolean;
}

const SatisfactionRating: FC<Props> = ({
  value,
  onChange,
  showLabel = true,
}): ReactElement => {
  const [hover, setHover] = useState<number | null>(null);

  const onChangeActive: NonNullable<RatingProps["onChangeActive"]> =
    useCallback((_event, value) => setHover(value == -1 ? null : value), []);

  const label = useMemo(() => {
    const hoverOrValue = hover ?? value;
    if (hoverOrValue == null) {
      return null;
    }
    switch (hoverOrValue) {
      case 1:
        return "Very Dissatisfied";
      case 2:
        return "Dissatisfied";
      case 3:
        return "Neutral";
      case 4:
        return "Satisfied";
      case 5:
        return "Very Satisfied";
      default:
        throw new Error(`Invalid rating ${hoverOrValue}`);
    }
  }, [hover, value]);

  const rating = useMemo(
    () => (
      <Rating
        value={value}
        onChange={onChange}
        onChangeActive={onChangeActive}
        IconContainerComponent={IconContainer}
        highlightSelectedOnly={true}
        sx={(theme) => ({
          "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
            color: theme.palette.action.disabled,
          },
        })}
      />
    ),
    [onChange, onChangeActive, value],
  );

  return showLabel ? (
    <Stack direction="row" alignItems="center" gap={1}>
      {rating}
      {label}
    </Stack>
  ) : (
    rating
  );
};

export default SatisfactionRating;
